import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    return (
        <div className='privacy-policy'>
            <Helmet>
                <html lang="en" />
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="#000000" />
                <meta
                    name="description"
                    content="Learn more about which personal information might be collected and your fundamentals rights regarding those informations" />
                <title>Privacy Policy</title>
            </Helmet>
            <Header />
            <div className='privacy-policy-container'>
                <h1>Privacy Policy</h1>
                <p>Last updated: March 20, 2022</p>
                <p>This website uses cookies to collect anonymous statistics about visitors usage (most visited pages, amount of visitors etc). we also collect names and email-adress in the contact form, if a visitor wants to contact us.</p>
                 <p>This Privacy Policy describes our policies and procedures on the collection, use and disclosure of your personnal information when you browse our website and tells you about your privacy rights and how the law protects you.</p>
                <p>By using this website, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
                <h2>Definitions</h2>
              <ul>
                    <li>
                        <p><strong>Cookies</strong> are small files that are placed on your computer, mobile device or any other device by a website, containing the details of your browsing history on that website among its many uses.</p>
                    </li>
                    <li>
                        <p><strong>Country</strong> refers to:  France</p>
                    </li>
                    <li>
                        <p><strong>Device</strong> means any device that can access the website such as a computer, a cellphone or a digital tablet.</p>
                    </li>
                    <li>
                        <p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
                        <p>For the purposes of GDPR, Personal Data means any information relating to you such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.</p>
                    </li>
                    <li>
                        <p><strong>usage Data</strong> refers to data collected automatically, either generated by the use of the website or from the website infrastructure itself (for example, the duration of a page visit).</p>
                    </li>
                    <li>
                        <p><strong>website</strong> refers to Final Fantasy Notes, accessible from <a href="http://ffnotes.com" rel="external nofollow noopener noreferrer" target="_blank">http://ffnotes.com</a></p>
                    </li>
                    <li>
                        <p><strong>you</strong> means the individual accessing or using this website.</p>
                        <p>Under GDPR (General Data Protection Regulation), you can be referred to as the Data Subject or as the user as you are the individual using the website.</p>
                    </li>
                </ul>
                <h2>Collecting and using your Personal Data</h2>
                <h3>Types of Data Collected</h3>
                <h3>Personal Data</h3>
                <p>While using our website, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information include:</p>
                <ul>
                    <li>
                        <p><strong>Email address</strong> (if you fill our contact form)</p>
                    </li>
                    <li>
                        <p><strong>First name and last name</strong> (if you fill our contact form)</p>
                    </li>
                    <li>
                        <p><strong>usage Data</strong></p>
                    </li>
                </ul>
                <h3>usage Data</h3>
                <p>usage Data is collected automatically when using the website.</p>
                <p>usage Data may include information such as the pages of our website that you visit, the time and date of your visit, the time spent on those pages.</p>
                <h3>Tracking Technologies and Cookies</h3>
                <p>we use Cookies and similar tracking technologies to track the activity on our website and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze our website. The technologies we use may include:</p>
                <ul>
                    <li><strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on your Device. you can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent.</li>
                    <li><strong>Flash Cookies.</strong> Certain features of our website may use local stored objects (or Flash Cookies) to collect and store information about your preferences or your activity on our website.</li>
                    <li><strong>web Beacons.</strong> Certain sections of our website and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</li>
                </ul>
                <h2>use of your Personal Data</h2>
                <p>The Company may use Personal Data for the following purposes:</p>
                <ul>
                    <li>
                        <p><strong>To provide and maintain our website</strong>, including to monitor the usage of our website.</p>
                    </li>
                    <li>
                        <p><strong>To contact you:</strong> To contact you by email, if you filled the contact form.</p>
                    </li>
                    <li>
                        <p><strong>To provide you</strong> with news, and general informationn.</p>
                    </li>
                    <li>
                        <p><strong>To manage your requests:</strong> To attend and manage your requests to us.</p>
                    </li>
                    <li>
                        <p><strong>For other purposes</strong>: we may use your information for other purposes, such as data analysis, identifying usage trends, to evaluate and improve our website.</p>
                    </li>
                </ul>
                <h2>Retention of your Personal Data</h2>
                <p>we can retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. we will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws).</p>
                <p>we will also retain usage Data for internal analysis purposes. usage Data is generally retained for a shorter period of time.</p>
                <h2>GDPR Privacy</h2>
                <h2>your Rights under the GDPR</h2>
                <p>we undertake to respect the confidentiality of your Personal Data and to guarantee you can exercise your rights.</p>
                <p>you have the right under this Privacy Policy, and by law if you are within the EU, to:</p>
                <ul>
                    <li><strong>Request access to your Personal Data.</strong> The right to access, update or delete the information we have on you. Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you. This also enables you to receive a copy of the Personal Data we hold about you.</li>
                    <li><strong>Request correction of the Personal Data that we hold about you.</strong> you have the right to have any incomplete or inaccurate information we hold about you corrected.</li>
                    <li><strong>Request erasure of your Personal Data.</strong> you have the right to ask us to delete or remove Personal Data when there is no good reason for us to continue processing it.</li>
                    <li><strong>Request the transfer of your Personal Data.</strong> we will provide to you, or to a third-party you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
                    <li><strong>Withdraw your consent.</strong> you have the right to withdraw your consent on using your Personal Data.</li>
                </ul>
                <h2>Exercising of your GDPR Data Protection Rights</h2>
                <p>you may exercise your rights of access, rectification, cancellation and opposition by contacting us. Please note that we may ask you to verify your identity before responding to such requests. If you make a request, we will try our best to respond to you as soon as possible.</p>
                <p>you have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, if you are in the European Economic Area (EEA), please contact your local data protection authority in the EEA.</p>
                <h2>Children's Privacy</h2>
                <p>our website does not address anyone under the age of 13. we do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from our servers.</p>
                <p>If we need to rely on consent as a legal basis for processing your information and your country requires consent from a parent, we may require your parent's consent before we collect and use that information.</p>
                <h2>Links to Other websites</h2>
                <p>our website may contain links to other websites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. we strongly advise you to review the Privacy Policy of every site you visit.</p>
                <p>we have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or websites.</p>
                <h2>Changes to this Privacy Policy</h2>
                <p>we may update our Privacy Policy from time to time. we will notify you of any changes by posting the new Privacy Policy on this page.</p>
                <p>we will let you know via email and/or a prominent notice on our website, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
                <p>you are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                <h2>Contact us</h2>
                <p>If you have any questions about this Privacy Policy, you can contact us:</p>
                <ul>
                    <li>By email: granierc@protonmail.com</li>
                </ul>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;